import React, { useState } from 'react';
import './Support.css';

function Support() {
  const [dept, setDept] = useState("general");
  const general = (
    <div>
      <h3>General Support</h3>
      <p>For general account or usage issues please contact the customer support team at: support@stakedsport.com</p>
    </div>
  );

  const finance = (
    <div>
      <h3>Finance Department</h3>
      <p>For any issues related to payments (deposits or withdrawals) or any balance transactions within the platform please contact the finance team at: finance@stakedsport.com</p>
    </div>
  );

  const tech = (
    <div>
      <h3>Techical Support/Bug Reporting</h3>
      <p>For technical support or to report a bug please contact the engineering team at: tech@stakedsport.com</p>
    </div>
  );


  return (
    <div>
      <h3>Support Departments</h3>
      <ul className="dept_list">
        <li className={dept === 'general'? "dept_list_item active_dept_list_item": "dept_list_item"} onClick={() => setDept("general")}>General Support</li>
        <li className={dept === 'finance'? "dept_list_item active_dept_list_item": "dept_list_item"} onClick={() => setDept("finance")}>Finance Department</li>
        <li className={dept === 'tech'? "dept_list_item active_dept_list_item": "dept_list_item"} onClick={() => setDept("tech")}>Technical Support</li>
      </ul>
      {dept === 'general'? general: null}
      {dept === 'finance'? finance: null}
      {dept === 'tech'? tech: null}
    </div>
  );
}

export default Support;
