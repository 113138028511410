import './Logo.css';

function Logo() {
  return (
      <div className="logo_container">
        <div className="staked_container">
          <div><h1>S</h1></div>
          <div><h1>T</h1></div>
          <div><h1>A</h1></div>
          <div><h1>K</h1></div>
          <div><h1>E</h1></div>
          <div><h1>D</h1></div>
        </div>
        <div className="sport_container">
          <div><h3>S</h3></div>
          <div><h3>P</h3></div>
          <div><h3>O</h3></div>
          <div><h3>R</h3></div>
          <div><h3>T</h3></div>
        </div>
      </div>
  );
}

export default Logo;
