import React, { useState , useEffect} from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Logo from "./components/Logo/Logo";
import Navbar from "./components/Navbar/Navbar";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
// import About from "./components/About/About";
import Support from "./components/Support/Support";
import MobileMenu from "./components/MobileMenu/MobileMenu";
import "./App.css";

function App() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  let location = useLocation();

  useEffect(() => {
    setShowMobileMenu(false);
  }, [location.pathname])
  
  return (
    <div>
      <div className="App-header">
        <Navbar showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu}/>
      </div>
      <div className="App">
      <Switch>
        {/* <Route path="/about">
          <About />
        </Route> */}
        <Route path="/privacy_policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/support">
          <Support />
        </Route>
        <Route path="/">
          <Logo />
        </Route>
      </Switch>
      {showMobileMenu? <MobileMenu showMenu={setShowMobileMenu}/>: null}
      </div>
    </div>
  );
}

export default App;
