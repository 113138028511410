import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';

function Navbar(props) {
  return (
    <nav className="nav_container">
      <ul className="nav_list">
        <li className="nav_list_item">
          <Link to="/">Home</Link>
        </li>
        {/* <li className="nav_list_item">
          <Link to="/about">About</Link>
        </li> */}
        <li className="nav_list_item">
          <Link to="/privacy_policy">Privacy</Link>
        </li>
        <li className="nav_list_item">
          <Link to="/support">Support</Link>
        </li>
        <li className="burger_icon" onClick={() => props.setShowMobileMenu(!props.showMobileMenu)}>
          <FontAwesomeIcon icon={faBars} />
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
