import React from "react";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './MobileMenu.css';

function MobileMenu() {
  let history = useHistory();
  return (
    <div>
      <nav className="mobile_nav_container">
        <ul className="mobile_nav_list">
          <li className="mobile_nav_list_item" onClick={() => history.push("/home")}>
          <Link to="/home">Home</Link>
            <FontAwesomeIcon className="link_icon" icon={faChevronRight} />
          </li>
          {/* <li className="mobile_nav_list_item" onClick={() => history.push("/about")}>
            <Link to="/about">About</Link>
            <FontAwesomeIcon className="link_icon" icon={faChevronRight} />
          </li> */}
          <li className="mobile_nav_list_item" onClick={() => history.push("/privacy_policy")}>
            <Link to="/privacy_policy">Privacy</Link>
            <FontAwesomeIcon className="link_icon" icon={faChevronRight} />
          </li>
          <li className="mobile_nav_list_item" onClick={() => history.push("/support")}>
            <Link to="/support">Support</Link>
            <FontAwesomeIcon className="link_icon" icon={faChevronRight} />
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default MobileMenu;
